import { Icon } from '@/types/Icon';

export default function SocialTiktok({ className }: Icon): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6451 2C15.953 4.69619 17.4307 6.30364 20 6.47465V9.50714C18.5111 9.65535 17.2068 9.15943 15.6899 8.2246V13.8963C15.6899 21.1013 7.97653 23.3529 4.8755 18.1885C2.88278 14.8653 4.10304 9.03403 10.4954 8.80032V11.9981C10.0084 12.0779 9.48786 12.2033 9.01207 12.3686C7.5903 12.8589 6.78425 13.7766 7.00816 15.3954C7.43916 18.4963 13.0255 19.4141 12.5609 13.3548V2.0057H15.6451V2Z"
        fill="currentColor"
      />
    </svg>
  );
}
