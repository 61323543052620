import { Icon } from '@/types/Icon';

export default function SocialLinkedIn({ className }: Icon): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6058 19.6056H16.4042V14.592C16.4042 13.398 16.3838 11.8596 14.7398 11.8596C13.0718 11.8596 12.8186 13.1628 12.8186 14.508V19.6056H9.61699V9.2976H12.6878V10.7064H12.7322C13.1594 9.8964 14.2046 9.042 15.7634 9.042C19.007 9.042 19.607 11.1756 19.607 13.9524V19.6056H19.6058ZM6.0062 7.89C5.76225 7.89023 5.52065 7.84242 5.29519 7.74928C5.06972 7.65615 4.86481 7.51952 4.69215 7.34719C4.51948 7.17486 4.38246 6.97022 4.28889 6.74493C4.19531 6.51965 4.14703 6.27814 4.1468 6.0342C4.14656 5.79025 4.19437 5.54865 4.28751 5.32319C4.38064 5.09772 4.51727 4.89281 4.6896 4.72015C4.86193 4.54749 5.06658 4.41046 5.29186 4.31689C5.51714 4.22332 5.75865 4.17503 6.00259 4.1748C6.49526 4.17432 6.96794 4.36957 7.31664 4.7176C7.66535 5.06563 7.86152 5.53793 7.86199 6.0306C7.86247 6.52326 7.66722 6.99594 7.31919 7.34465C6.97116 7.69335 6.49886 7.88952 6.0062 7.89ZM4.4018 19.6056H7.6082V9.2976H4.4006V19.6056H4.4018ZM21.2018 1.2H2.7938C1.9142 1.2 1.2002 1.8972 1.2002 2.7576V21.2412C1.2002 22.1016 1.9142 22.8 2.7938 22.8H21.2018C22.0826 22.8 22.8002 22.1016 22.8002 21.2412V2.7576C22.8002 1.8972 22.0826 1.2 21.2018 1.2Z"
        fill="currentColor"
      />
    </svg>
  );
}
