import { Icon } from '@/types/Icon';

export default function LogoPrimary({ className }: Icon): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 126 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.698 27.9411C52.5147 27.9396 51.3804 27.4715 50.5437 26.6395C49.707 25.8075 49.2363 24.6795 49.2347 23.5029V13.15H54.044V9.32644H49.2347V4.7735H45.3896V23.5029C45.3896 24.9649 45.7796 26.4007 46.52 27.6638C47.2604 28.9269 48.3246 29.972 49.6039 30.6925C50.8833 31.4129 52.332 31.7828 53.8021 31.7645C55.2722 31.7462 56.7111 31.3403 57.9719 30.5882L55.9902 27.3088C55.2983 27.7231 54.5057 27.9418 53.698 27.9411Z"
        fill="#262626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.305 9.13703C74.4867 9.47126 76.5246 10.4256 78.1733 11.8852V9.38814H82.0184V31.3852H78.1733V28.8881C76.5246 30.3477 74.4867 31.3021 72.305 31.6363C70.1234 31.9705 67.891 31.6703 65.877 30.7719C63.863 29.8734 62.1532 28.4151 60.9535 26.5725C59.7539 24.7299 59.1157 22.5817 59.1157 20.3867C59.1157 18.1916 59.7539 16.0434 60.9535 14.2008C62.1532 12.3582 63.863 10.8999 65.877 10.0014C67.891 9.103 70.1234 8.80281 72.305 9.13703ZM66.3552 26.6689C67.6053 27.4989 69.0747 27.9416 70.5778 27.9411C72.5916 27.9387 74.5221 27.1422 75.9461 25.7263C77.37 24.3103 78.171 22.3906 78.1733 20.3881C78.1739 18.8935 77.7287 17.4322 76.894 16.1892C76.0593 14.9462 74.8726 13.9773 73.484 13.405C72.0955 12.8328 70.5674 12.6829 69.0932 12.9744C67.619 13.2658 66.2648 13.9855 65.202 15.0424C64.1391 16.0993 63.4154 17.4459 63.1223 18.9118C62.8292 20.3778 62.9799 21.8973 63.5554 23.2781C64.1309 24.6588 65.1052 25.8389 66.3552 26.6689Z"
        fill="#262626"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.4065 11.8853C92.4976 10.0297 95.2024 9.00474 98.005 9.00589C99.8577 9.00607 101.683 9.45371 103.323 10.3103C104.963 11.1669 106.37 12.4068 107.422 13.9234C108.474 15.4401 109.139 17.1881 109.362 19.0171C109.584 20.8462 109.356 22.7016 108.698 24.4237C108.04 26.1459 106.971 27.6834 105.583 28.904C104.195 30.1246 102.53 30.9917 100.731 31.4309C98.9316 31.87 97.0519 31.8681 95.2535 31.4252C93.4551 30.9823 91.7918 30.1117 90.4065 28.8882V40H86.5615V9.38824H90.4065V11.8853ZM93.78 26.6733C95.0312 27.5023 96.5015 27.9435 98.005 27.9412C100.017 27.9357 101.945 27.1379 103.367 25.7224C104.788 24.3069 105.588 22.3889 105.592 20.3882C105.592 18.8932 105.147 17.4316 104.312 16.1884C103.477 14.9452 102.289 13.9763 100.9 13.4043C99.5112 12.8323 97.9827 12.6829 96.5082 12.9751C95.0338 13.2673 93.6796 13.9878 92.6171 15.0456C91.5546 16.1034 90.8316 17.4508 90.5395 18.9174C90.2474 20.3839 90.3994 21.9037 90.9762 23.2843C91.5531 24.6649 92.5288 25.8443 93.78 26.6733Z"
        fill="#262626"
      />
      <path
        d="M121.772 27.9415C122.563 27.9329 123.337 27.7156 124.015 27.3117L126 30.5882C124.739 31.341 123.3 31.7475 121.829 31.7662C120.359 31.785 118.91 31.4152 117.63 30.6946C116.35 29.9741 115.285 28.9287 114.545 27.6652C113.804 26.4017 113.414 24.9653 113.415 23.5029V4.7735H117.26V9.31173H122.066V13.1353H117.26V23.4882C117.257 24.2745 117.465 25.0474 117.861 25.7278C118.257 26.4083 118.828 26.9718 119.515 27.3608C120.203 27.7497 120.981 27.9501 121.772 27.9415Z"
        fill="#262626"
      />
      <path
        d="M21.84 3.87059C21.84 5.87993 20.2019 7.50882 18.1813 7.50882C16.1606 7.50882 14.5225 5.87993 14.5225 3.87059C14.5225 1.86125 16.1606 0.232361 18.1813 0.232361C20.2019 0.232361 21.84 1.86125 21.84 3.87059Z"
        fill="#873CFF"
      />
      <path
        d="M11.7363 8.03232C11.7363 10.0417 10.0982 11.6706 8.07756 11.6706C6.05689 11.6706 4.41882 10.0417 4.41882 8.03232C4.41882 6.02298 6.05689 4.39409 8.07756 4.39409C10.0982 4.39409 11.7363 6.02298 11.7363 8.03232Z"
        fill="#873CFF"
      />
      <path
        d="M3.89238 21.7177C5.91304 21.7177 7.55111 20.0888 7.55111 18.0794C7.55111 16.0701 5.91304 14.4412 3.89238 14.4412C1.87171 14.4412 0.233643 16.0701 0.233643 18.0794C0.233643 20.0888 1.87171 21.7177 3.89238 21.7177Z"
        fill="#873CFF"
      />
      <path
        d="M11.7363 28.1294C11.7363 30.1387 10.0982 31.7676 8.07756 31.7676C6.05689 31.7676 4.41882 30.1387 4.41882 28.1294C4.41882 26.12 6.05689 24.4912 8.07756 24.4912C10.0982 24.4912 11.7363 26.12 11.7363 28.1294Z"
        fill="#873CFF"
      />
      <path
        d="M18.1813 35.9294C20.2019 35.9294 21.84 34.3005 21.84 32.2911C21.84 30.2818 20.2019 28.6529 18.1813 28.6529C16.1606 28.6529 14.5225 30.2818 14.5225 32.2911C14.5225 34.3005 16.1606 35.9294 18.1813 35.9294Z"
        fill="#873CFF"
      />
      <path
        d="M31.9466 28.1294C31.9466 30.1387 30.3085 31.7676 28.2879 31.7676C26.2672 31.7676 24.6291 30.1387 24.6291 28.1294C24.6291 26.12 26.2672 24.4912 28.2879 24.4912C30.3085 24.4912 31.9466 26.12 31.9466 28.1294Z"
        fill="#873CFF"
      />
      <path
        d="M32.473 21.7177C34.4937 21.7177 36.1318 20.0888 36.1318 18.0794C36.1318 16.0701 34.4937 14.4412 32.473 14.4412C30.4524 14.4412 28.8143 16.0701 28.8143 18.0794C28.8143 20.0888 30.4524 21.7177 32.473 21.7177Z"
        fill="#873CFF"
      />
      <path
        d="M31.9466 8.03232C31.9466 10.0417 30.3085 11.6706 28.2879 11.6706C26.2672 11.6706 24.6291 10.0417 24.6291 8.03232C24.6291 6.02298 26.2672 4.39409 28.2879 4.39409C30.3085 4.39409 31.9466 6.02298 31.9466 8.03232Z"
        fill="#873CFF"
      />
    </svg>
  );
}
