import { Icon } from '@/types/Icon';

export default function PaymentApple({ className }: Icon): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 38 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.3872 0H3.61287C3.48468 0 3.35626 0 3.2283 0.00067947C3.12026 0.00158543 3.01245 0.00271788 2.90441 0.00566225C2.66909 0.012004 2.43173 0.0260463 2.19935 0.0677205C1.9663 0.107648 1.74051 0.182074 1.52939 0.288548C1.10499 0.504411 0.760026 0.849374 0.544163 1.27378C0.4375 1.48497 0.363065 1.71095 0.323336 1.94419C0.281209 2.17679 0.267393 2.41393 0.261051 2.64903C0.258291 2.75697 0.25663 2.86493 0.256068 2.97291C0.255615 3.10065 0.255615 3.22884 0.255615 3.35726V20.6425C0.255615 20.7709 0.255615 20.8989 0.256068 21.0273C0.256748 21.1351 0.258107 21.2432 0.261051 21.3512C0.267393 21.5858 0.281435 21.8232 0.323336 22.0556C0.362983 22.2888 0.437342 22.5148 0.543937 22.726C0.651108 22.9369 0.790999 23.1296 0.958413 23.2967C1.12554 23.4642 1.31819 23.6041 1.52917 23.7112C1.74365 23.8206 1.96335 23.8902 2.19958 23.9325C2.43173 23.974 2.66909 23.988 2.90441 23.9943C3.01245 23.9968 3.12026 23.9982 3.2283 23.9989C3.35626 23.9998 3.48468 23.9998 3.61287 23.9998H34.3872C34.5152 23.9998 34.6436 23.9998 34.7715 23.9989C34.8794 23.9984 34.9872 23.9968 35.0956 23.9943C35.3305 23.988 35.5679 23.974 35.8007 23.9325C36.0338 23.8925 36.2596 23.818 36.4707 23.7112C36.8949 23.4951 37.2397 23.1502 37.4559 22.726C37.5653 22.5115 37.6346 22.2918 37.6765 22.0556C37.7186 21.8232 37.7322 21.5858 37.7386 21.3512C37.7415 21.2432 37.7431 21.1351 37.7435 21.0273C37.7444 20.8989 37.7444 20.7709 37.7444 20.6425V3.35749C37.7444 3.22907 37.7444 3.10087 37.7435 2.97268C37.7431 2.86478 37.7414 2.75689 37.7386 2.64903C37.7322 2.41393 37.7186 2.17679 37.6765 1.94419C37.6371 1.71091 37.5627 1.4849 37.4559 1.27378C37.2399 0.849448 36.895 0.504513 36.4707 0.288548C36.2595 0.182184 36.0337 0.107836 35.8007 0.067947C35.5679 0.0260463 35.3305 0.0122305 35.0956 0.00588874C34.9876 0.00314533 34.8796 0.00148432 34.7715 0.000905959C34.6436 0 34.5152 0 34.3872 0Z"
        fill="black"
      />
      <path
        d="M34.3871 0.799988L34.7658 0.800667C34.8682 0.801347 34.9708 0.802479 35.0739 0.805197C35.2535 0.81018 35.4632 0.819919 35.6589 0.855025C35.8288 0.885601 35.9714 0.932031 36.1082 1.00179C36.382 1.14098 36.6044 1.36363 36.7433 1.63755C36.8126 1.77344 36.8588 1.91522 36.8894 2.08668C36.9243 2.2801 36.934 2.49051 36.9388 2.67102C36.9417 2.77294 36.9431 2.87486 36.9433 2.97905C36.9444 3.1052 36.9444 3.23113 36.9444 3.35751V20.6428C36.9444 20.7691 36.9444 20.8951 36.9435 21.0237C36.9431 21.1256 36.9417 21.2276 36.939 21.3295C36.934 21.5098 36.9243 21.7202 36.889 21.9159C36.8633 22.0715 36.814 22.2224 36.7429 22.3632C36.6037 22.6372 36.3809 22.8598 36.1069 22.9989C35.971 23.0682 35.8288 23.1147 35.6605 23.145C35.4609 23.1806 35.2421 23.1903 35.0775 23.1949C34.974 23.1971 34.8709 23.1987 34.7652 23.1992C34.6395 23.2001 34.5131 23.2001 34.3871 23.2001H3.60786C3.48329 23.2001 3.35872 23.2001 3.23188 23.1992C3.12995 23.1987 3.02803 23.1974 2.92612 23.1951C2.75761 23.1906 2.53882 23.1806 2.34087 23.1453C2.18436 23.1192 2.03271 23.0695 1.89106 22.998C1.7552 22.929 1.63119 22.8388 1.5237 22.7308C1.41587 22.6231 1.32581 22.4991 1.25689 22.3632C1.18572 22.2216 1.13639 22.07 1.11058 21.9136C1.07525 21.7181 1.06528 21.5086 1.06075 21.3297C1.05819 21.2274 1.05668 21.1251 1.05622 21.0228L1.05554 20.7223V3.27824L1.05599 2.97814C1.05646 2.87568 1.05797 2.77323 1.06052 2.67079C1.06551 2.49141 1.07525 2.28191 1.11081 2.08486C1.13666 1.92878 1.18599 1.77751 1.25712 1.63619C1.32597 1.50075 1.41566 1.37708 1.52438 1.2686C1.63213 1.16068 1.75627 1.07048 1.8922 1.00134C2.03349 0.93035 2.18467 0.881027 2.34065 0.855025C2.53656 0.819919 2.74652 0.81018 2.92635 0.805197C3.02872 0.802479 3.13132 0.801347 3.23302 0.800667L3.61284 0.799988H34.3871Z"
        fill="white"
      />
      <path
        d="M10.1098 8.10153C10.4278 7.70404 10.6434 7.1702 10.5865 6.62482C10.1213 6.64792 9.55376 6.93171 9.22489 7.32965C8.92978 7.67029 8.66863 8.22633 8.73658 8.74884C9.25886 8.79414 9.7807 8.48792 10.1098 8.10153Z"
        fill="black"
      />
      <path
        d="M10.5806 8.851C9.82187 8.80571 9.17727 9.28133 8.81512 9.28133C8.45273 9.28133 7.89806 8.87365 7.29809 8.88475C6.51738 8.89608 5.79306 9.33773 5.39671 10.0398C4.58179 11.4441 5.18154 13.5278 5.97425 14.6716C6.35906 15.2378 6.82291 15.8606 7.43398 15.8382C8.01153 15.8156 8.23779 15.4645 8.93946 15.4645C9.6409 15.4645 9.84474 15.8382 10.456 15.8269C11.0898 15.8156 11.4861 15.2607 11.8709 14.6944C12.3126 14.0489 12.4931 13.4261 12.5044 13.3921C12.4931 13.3808 11.2823 12.9163 11.2709 11.5234C11.2596 10.3569 12.2218 9.80203 12.267 9.76783C11.7237 8.96425 10.8748 8.87365 10.5804 8.851"
        fill="black"
      />
      <path
        d="M18.1422 7.27258C19.7906 7.27258 20.9386 8.40888 20.9386 10.0632C20.9386 11.7233 19.767 12.8655 18.101 12.8655H16.2759V15.7682H14.9573V7.27258H18.1422ZM16.2759 11.7587H17.7889C18.9372 11.7587 19.5904 11.1406 19.5904 10.0691C19.5904 8.99776 18.9369 8.38533 17.7947 8.38533H16.2759V11.7587Z"
        fill="black"
      />
      <path
        d="M21.2657 14.0077C21.2657 12.9185 22.0958 12.2946 23.6265 12.2003L25.269 12.1002V11.6291C25.269 10.9406 24.8157 10.5637 24.0092 10.5637C23.3438 10.5637 22.8609 10.905 22.7613 11.4289H21.5717C21.6073 10.3282 22.6433 9.52753 24.0446 9.52753C25.5516 9.52753 26.5348 10.3164 26.5348 11.541V15.768H25.3163V14.7495H25.2868C24.9394 15.4147 24.1739 15.8326 23.3438 15.8326C22.1194 15.8326 21.2657 15.1028 21.2657 14.0077ZM25.269 13.4603V12.9833L23.8031 13.0775C22.9787 13.1305 22.5493 13.4365 22.5493 13.9722C22.5493 14.4904 22.9966 14.826 23.6971 14.826C24.5918 14.826 25.269 14.2548 25.269 13.4603Z"
        fill="black"
      />
      <path
        d="M27.6552 18.0404V17.0221C27.7376 17.0336 27.9376 17.0454 28.0436 17.0454C28.6263 17.0454 28.9561 16.7985 29.1563 16.1623L29.2741 15.7857L27.0427 9.60406H28.4202L29.9746 14.6199H30.0041L31.5583 9.60406H32.9007L30.5868 16.0975C30.0569 17.5872 29.4505 18.0759 28.1672 18.0759C28.0671 18.0759 27.7433 18.0641 27.6552 18.0404Z"
        fill="black"
      />
    </svg>
  );
}
