import { Icon } from '@/types/Icon';

export default function PaymentMastercard({ className }: Icon): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 38 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.07"
        d="M35 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.4 24 3 24H35C36.7 24 38 22.7 38 21V3C38 1.3 36.6 0 35 0Z"
        fill="black"
      />
      <path
        d="M35 1C36.1 1 37 1.9 37 3V21C37 22.1 36.1 23 35 23H3C1.9 23 1 22.1 1 21V3C1 1.9 1.9 1 3 1H35Z"
        fill="white"
      />
      <path
        d="M15 19C18.866 19 22 15.866 22 12C22 8.13401 18.866 5 15 5C11.134 5 8 8.13401 8 12C8 15.866 11.134 19 15 19Z"
        fill="#EB001B"
      />
      <path
        d="M23 19C26.866 19 30 15.866 30 12C30 8.13401 26.866 5 23 5C19.134 5 16 8.13401 16 12C16 15.866 19.134 19 23 19Z"
        fill="#F79E1B"
      />
      <path
        d="M22 12C22 9.59999 20.8 7.49999 19 6.29999C17.2 7.59999 16 9.69999 16 12C16 14.3 17.2 16.5 19 17.7C20.8 16.5 22 14.4 22 12Z"
        fill="#FF5F00"
      />
    </svg>
  );
}
