import classNames from '@/helpers/classNames';

export default function Wrapper(props: {
  children: React.ReactNode;
  small?: boolean;
}): JSX.Element {
  const { children, small = false } = props;

  return (
    <div
      className={classNames(
        'relative md:h-full mx-auto box-content px-4 md:px-8 lg:px-12 2xl:px-0',
        small ? 'max-w-3.5xl' : 'max-w-7xl',
      )}
    >
      {children}
    </div>
  );
}
