import { Icon } from '@/types/Icon';

export default function SocialYoutube({ className }: Icon): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.582 6.186C21.352 5.326 20.674 4.648 19.814 4.418C18.254 4 12 4 12 4C12 4 5.746 4 4.186 4.418C3.326 4.648 2.648 5.326 2.418 6.186C2 7.746 2 12 2 12C2 12 2 16.254 2.418 17.814C2.648 18.674 3.326 19.352 4.186 19.582C5.746 20 12 20 12 20C12 20 18.254 20 19.814 19.582C20.675 19.352 21.352 18.674 21.582 17.814C22 16.254 22 12 22 12C22 12 22 7.746 21.582 6.186ZM10 14.598V9.402C10 9.017 10.417 8.777 10.75 8.969L15.25 11.567C15.583 11.759 15.583 12.241 15.25 12.433L10.75 15.031C10.417 15.224 10 14.983 10 14.598Z"
        fill="currentColor"
      />
    </svg>
  );
}
