import { useState } from 'react';
import Footer, { FooterData } from '@/components/Footer';
import Header, { HeaderData } from '@/components/Header';
import SidebarNavigation from '@/components/SidebarNavigation';

export default function Layout(props: {
  children: React.ReactNode;
  header: HeaderData;
  footer: FooterData;
  showOnlyFooter?: boolean;
  hideHeaderNavigation?: boolean;
}): JSX.Element {
  const { children, header, footer, showOnlyFooter, hideHeaderNavigation } =
    props;
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigation = [
    {
      name: 'Products',
      href: '/products/',
    },
    ...(header?.attributes?.primaryLinks?.data
      ? header.attributes.primaryLinks.data.map(
          ({ attributes: { name, slug } }) => ({ name, href: `/${slug}/` }),
        )
      : []),
    ...(header?.attributes?.showLookbookListingInHeader
      ? [
          {
            name: 'Lookbook',
            href: '/products/lookbook/',
          },
        ]
      : []),
    {
      name: 'Help',
      href: '/help/',
    },
  ];

  const footerNavigation = [
    ...(footer?.attributes?.primaryLinks?.data
      ? footer.attributes.primaryLinks.data.map(
          ({ attributes: { name, slug } }) => ({ name, href: `/${slug}/` }),
        )
      : []),
    ...(footer?.attributes?.showLookbookListingInFooter
      ? [
          {
            name: 'Lookbook',
            href: '/products/lookbook/',
          },
        ]
      : []),
    {
      name: 'Blog',
      href: '/blog/',
    },
    {
      name: 'Sales Enquiry',
      href: '/sales-enquiry/',
    },
    {
      name: 'Help center',
      href: 'https://help.tapt.io',
      external: true,
    },
    {
      name: 'Contact us',
      href: '/contact-us/',
    },
  ];
  const footerNavigationData =
    footer?.attributes?.footerLinks?.length > 0
      ? footer?.attributes?.footerLinks.map((i) => ({
          name: i.label,
          href: i.link,
          external: i.isExternal,
        }))
      : footerNavigation;
  return (
    <>
      {!showOnlyFooter && (
        <SidebarNavigation
          navigation={navigation}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
      )}
      {!showOnlyFooter && (
        <Header
          setSidebarOpen={setSidebarOpen}
          navigation={navigation}
          hideHeaderNavigation={hideHeaderNavigation}
        />
      )}
      <main>{children}</main>
      <Footer
        data={footer}
        navigation={footerNavigationData}
        showEqualSpacing={footer?.attributes?.footerLinks?.length > 0}
      />
    </>
  );
}
