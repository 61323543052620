import { Icon } from '@/types/Icon';

export default function PaymentJcb({ className }: Icon): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 38 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.07"
        d="M35 0H3C1.3 0 0 1.3 0 3V21C0 22.7 1.4 24 3 24H35C36.7 24 38 22.7 38 21V3C38 1.3 36.6 0 35 0Z"
        fill="black"
      />
      <path
        d="M35 1C36.1 1 37 1.9 37 3V21C37 22.1 36.1 23 35 23H3C1.9 23 1 22.1 1 21V3C1 1.9 1.9 1 3 1H35Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 5H15V16.5C15 17.163 14.7366 17.7989 14.2678 18.2678C13.7989 18.7366 13.163 19 12.5 19H9V7.5C9 6.83696 9.26339 6.20107 9.73223 5.73223C10.2011 5.26339 10.837 5 11.5 5Z"
        fill="#006EBC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 5H22V16.5C22 17.163 21.7366 17.7989 21.2678 18.2678C20.7989 18.7366 20.163 19 19.5 19H16V7.5C16 6.83696 16.2634 6.20107 16.7322 5.73223C17.2011 5.26339 17.837 5 18.5 5Z"
        fill="#F00036"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 5H29V16.5C29 17.163 28.7366 17.7989 28.2678 18.2678C27.7989 18.7366 27.163 19 26.5 19H23V7.5C23 6.83696 23.2634 6.20107 23.7322 5.73223C24.2011 5.26339 24.837 5 25.5 5Z"
        fill="#2AB419"
      />
      <path
        d="M10.755 14.5C9.695 14.5 8.633 14.196 8.099 13.513L8.879 12.837C8.947 13.97 12.424 14.077 12.424 12.647V9.50003H14.226V12.647C14.226 13.375 13.652 13.969 12.653 14.279C12.187 14.423 11.288 14.5 10.755 14.5ZM18.871 14.5C18.197 14.5 17.483 14.393 16.906 14.134C15.958 13.709 15.594 12.928 15.606 11.935C15.618 10.921 16.042 10.153 17.074 9.77003C18.393 9.28003 20.417 9.50903 21 10.04V11.012C20.428 10.491 19.042 10.114 18.081 10.552C17.587 10.778 17.344 11.469 17.337 12C17.331 12.56 17.582 13.252 18.081 13.497C19.034 13.964 20.471 13.537 21 13.056V14.066C20.642 14.321 19.747 14.5 18.871 14.5ZM27.55 11.913C27.92 11.678 28.132 11.346 28.132 10.908C28.132 10.47 28.016 10.221 27.784 9.96903C27.578 9.76203 27.204 9.50003 26.546 9.50003H23V14.5H26.546C27.242 14.5 27.643 14.27 27.861 14.085C28.144 13.835 28.287 13.555 28.287 13.125C28.287 12.694 28.132 12.217 27.55 11.913ZM25.644 11.632H24.216V10.188H25.711C26.667 10.188 26.655 11.632 25.644 11.632ZM25.932 13.789H24.216V12.276H25.932C26.918 12.276 27.015 13.789 25.932 13.789Z"
        fill="white"
      />
    </svg>
  );
}
