import { Fragment, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import User from './icons/User';

export default function SidebarNavigation(props: {
  navigation: {
    name: string;
    href: string;
  }[];
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const router = useRouter();
  const { pathname } = router;
  const { navigation, sidebarOpen, setSidebarOpen } = props;

  useEffect(() => {
    if (pathname) {
      setSidebarOpen(false);
    }
  }, [pathname, setSidebarOpen]);

  return (
    <Transition.Root show={sidebarOpen} as={Fragment} key={pathname}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-grey-900 bg-opacity-50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="flex flex-col w-72 h-full">
            {/* Sidebar */}
            <div className="relative flex-1 flex flex-col w-full max-h-full bg-white overflow-y-auto">
              <div className="flex items-center h-16">
                <button
                  className="m-2 py-1 pr-1 focus:outline-none"
                  onClick={() => setSidebarOpen(false)}
                  type="button"
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="flex-1 flex items-center">
                <nav className="w-full px-4 space-y-5">
                  {navigation.map((item) => (
                    <Link key={item.name} href={item.href} passHref>
                      <a
                        href={item.href}
                        className="group flex items-center text-base leading-5 font-medium"
                      >
                        {item.name}
                      </a>
                    </Link>
                  ))}
                  <a
                    href="https://cards.tapt.io"
                    className="group flex items-center text-base leading-5 font-medium"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <User className="h-6 w-6 mr-3" />
                    Login
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
