import Link from 'next/link';
import { useRouter } from 'next/router';
import { MenuAlt2Icon } from '@heroicons/react/outline';
import Wrapper from '@/containers/Wrapper';
import classNames from '@/helpers/classNames';
import useShopify from '@/hooks/useShopify';
import useScrollDirection from '@/hooks/useScrollDirection';
import useAuth from '@/hooks/useAuth';
import LogoPrimary from './icons/logos/Primary';
import Cart from './icons/Cart';
import User from './icons/User';

export interface HeaderData {
  id: number;
  attributes: {
    primaryLinks: {
      data: {
        id: number;
        attributes: {
          name: string;
          slug: string;
        };
      }[];
    };
    showLookbookListingInHeader: boolean;
  };
}
interface IHeader {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navigation: {
    name: string;
    href: string;
  }[];
  hideHeaderNavigation?: boolean;
}

function Header(props: IHeader) {
  const { navigation, setSidebarOpen, hideHeaderNavigation } = props;
  const router = useRouter();
  const { asPath } = router;
  const { cart } = useShopify();
  const scrollDirection = useScrollDirection();
  const { user } = useAuth();

  return (
    <header
      className={classNames(
        'sticky w-full shadow-soft h-[62px] md:h-[90px] bg-white z-[999] transition-all duration-300 hide_me',
        scrollDirection === 'down' ? '-top-[62px] md:-top-[90px]' : 'top-0',
      )}
    >
      <div className="box-content relative h-full px-4 mx-auto md:h-full md:px-8 lg:px-12 2xl:px-0 max-w-7xl">
        {!hideHeaderNavigation ? (
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <button
                className="py-1 mr-4 focus:outline-none md:hidden"
                onClick={() => setSidebarOpen(true)}
                type="button"
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt2Icon
                  className="w-5 h-5 text-gray-900"
                  aria-hidden="true"
                />
              </button>
              <Link href="/" passHref aria-label="">
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a href="/">
                  <span className="hidden md:block" style={{ height: '40px' }}>
                    <LogoPrimary className="h-[40px] w-[126px]" />
                  </span>
                  <span className="md:hidden" style={{ height: '30px' }}>
                    <LogoPrimary className="h-[30px] w-[94.5px]" />
                  </span>
                </a>
              </Link>
              <nav className="hidden md:block ml-15">
                {navigation &&
                  navigation.length > 0 &&
                  navigation.map((item) => (
                    <Link key={item.href} href={item.href} passHref>
                      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                      <a
                        href={item.href}
                        className={classNames(
                          'text-lg py-7.5 border-b-2 inline-block mr-7.5',
                          asPath === item.href
                            ? 'text-purple-700 border-purple-700'
                            : 'border-transparent',
                        )}
                        aria-label=""
                      >
                        {item.name}
                      </a>
                    </Link>
                  ))}
              </nav>
            </div>
            <div className="flex items-center">
              <a
                href="https://cards.tapt.io"
                className={classNames(
                  'hidden md:flex items-center py-5 md:py-7.5 text-lg border-b-2',
                  asPath === '/login/'
                    ? 'text-purple-700 border-purple-700'
                    : 'border-transparent',
                )}
                aria-label=""
                target="_blank"
                rel="noreferrer"
              >
                <User className="inline-block w-5 h-5 mr-1" />
                <span>
                  {user ? `${user.first_name} ${user.last_name}` : 'Login'}
                </span>
              </a>
              <Link href="/cart" passHref aria-label="">
                {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                <a
                  href="/cart"
                  className={classNames(
                    'flex items-center py-5 md:py-7.5 text-lg border-b-2 ml-7.5',
                    asPath === '/cart/'
                      ? 'text-purple-700 border-purple-700'
                      : 'border-transparent',
                  )}
                  aria-label=""
                >
                  {cart && cart?.items?.length > 0 && (
                    <span className="bg-purple-500 font-medium text-white rounded-full text-xs leading-4.5 w-4.5 h-4.5 text-center mr-1 leading-3.5">
                      {cart.items.length}
                    </span>
                  )}
                  <Cart className="inline-block w-5 h-5" />
                  <span className="hidden ml-1 md:block">Cart</span>
                </a>
              </Link>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between w-full h-full">
            <Link href="/" passHref aria-label="">
              {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
              <a href="/">
                <span className="hidden md:block" style={{ height: '40px' }}>
                  <LogoPrimary className="h-[40px] w-[126px]" />
                </span>
                <span className="md:hidden" style={{ height: '30px' }}>
                  <LogoPrimary className="h-[30px] w-[94.5px]" />
                </span>
              </a>
            </Link>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
