import Link from 'next/link';
import Image from 'next/image';
import { ArrowUpRight } from 'react-feather';
import Wrapper from '@/containers/Wrapper';
import useShopify from '@/hooks/useShopify';
import clsx from 'clsx';
import LogoSecondayWhite from '../assets/logos/secondary-white.svg';
import SocialLinkedIn from './icons/SocialLinkedIn';
import SocialPinterest from './icons/SocialPinterest';
import SocialInstagram from './icons/SocialInstagram';
import SocialTiktok from './icons/SocialTiktok';
import SocialYoutube from './icons/SocialYoutube';
import PaymentAmex from './icons/PaymentAmex';
import PaymentApple from './icons/PaymentApple';
import PaymentGoogle from './icons/PaymentGoogle';
import PaymentJcb from './icons/PaymentJcb';
import PaymentPay from './icons/PaymentPay';
import PaymentVisa from './icons/PaymentVisa';
import PaymentMastercard from './icons/PaymentMastercard';
import SocialFacebook from './icons/SocialFacebook';
import SocialWhatsapp from './icons/SocialWhatsapp';
import SocialTwitter from './icons/SocialTwitter';
import SocialWechat from './icons/SocialWechat';
import SocialGoogleMyBusiness from './icons/SocialGoogleMyBusiness';
import InputSelect from './InputSelect';

type FooterLinkType = {
  label: string;
  link: string;
  isExternal: boolean;
};
export interface FooterData {
  id: number;
  attributes: {
    heading: string;
    socialLinks: {
      id: number;
      channel: string;
      link: string;
    }[];
    primaryLinks: {
      data: {
        id: number;
        attributes: {
          name: string;
          slug: string;
        };
      }[];
    };
    secondaryLinks: {
      data: {
        id: number;
        attributes: {
          name: string;
          slug: string;
        };
      }[];
    };
    paymentOptions: {
      data: {
        id: number;
        attributes: {
          providor:
            | 'ApplePay'
            | 'GooglePay'
            | 'AMEX'
            | 'JCB'
            | 'Pay'
            | 'VISA'
            | 'MasterCard';
        };
      }[];
    };
    showLookbookListingInFooter: boolean;
    footerLinks: FooterLinkType[];
  };
}

function Footer(props: {
  data: FooterData;
  navigation: {
    name: string;
    href: string;
    external?: boolean;
  }[];
  showEqualSpacing?: boolean;
}) {
  const { data, navigation, showEqualSpacing } = props;
  const {
    enabledPresentmentCurrencies,
    currency,
    selectCurrency,
    currencySymbol,
  } = useShopify();

  const secondaryLinks = [
    ...(data?.attributes?.secondaryLinks?.data
      ? data.attributes.secondaryLinks.data.map(
          ({ attributes: { name, slug } }) => ({ name, href: `/${slug}/` }),
        )
      : []),
  ];

  return (
    <footer className="w-full hide_me">
      <div className="pt-16 pb-12 bg-grey-900 md:pt-20 md:pb-16">
        <Wrapper>
          <Link href="/" passHref>
            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
            <a href="/" className="inline-block mb-4">
              <span className="block" style={{ height: '38px' }}>
                <Image
                  src={LogoSecondayWhite as string}
                  alt="Tapt"
                  height={38}
                  width={38}
                />
              </span>
            </a>
          </Link>
          <div className="w-full md:grid md:grid-cols-12">
            <div className="col-span-4">
              {data?.attributes?.heading && (
                <p className="font-bold text-lg leading-5.5 text-white mb-1.5 md:mb-4 md:pr-12">
                  {data.attributes.heading}
                </p>
              )}
              <div className="flex flex-wrap mb-5.5 md:mb-0">
                {data?.attributes?.socialLinks &&
                  data.attributes.socialLinks.map((socialLink) => {
                    let Icon = null;
                    switch (socialLink.channel) {
                      case 'LinkedIn':
                        Icon = SocialLinkedIn;
                        break;
                      case 'Pinterest':
                        Icon = SocialPinterest;
                        break;
                      case 'Instagram':
                        Icon = SocialInstagram;
                        break;
                      case 'TikTok':
                        Icon = SocialTiktok;
                        break;
                      case 'YouTube':
                        Icon = SocialYoutube;
                        break;
                      case 'Facebook':
                        Icon = SocialFacebook;
                        break;
                      case 'WhatsApp':
                        Icon = SocialWhatsapp;
                        break;
                      case 'Twitter':
                        Icon = SocialTwitter;
                        break;
                      case 'Wechat':
                        Icon = SocialWechat;
                        break;
                      case 'GoogleMyBusiness':
                        Icon = SocialGoogleMyBusiness;
                        break;
                      default:
                        break;
                    }

                    return (
                      <a
                        key={socialLink.id}
                        href={socialLink.link}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center justify-center mt-4 mr-4 rounded-full bg-grey-700 h-9 w-9"
                      >
                        {Icon && (
                          <Icon className="inline-block w-5 h-5 text-white" />
                        )}
                      </a>
                    );
                  })}
              </div>
            </div>
            <div className="grid w-full grid-cols-1 col-span-5 md:grid-cols-2">
              {navigation &&
                navigation.length > 0 &&
                navigation.map((item) => {
                  if (item.external) {
                    return (
                      <a
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                        className={clsx(
                          'text-lg leading-7 text-white flex ',
                          showEqualSpacing
                            ? 'items-start mb-7.5'
                            : 'items-center',
                        )}
                      >
                        {item.name}
                        {showEqualSpacing ? (
                          <div className="flex items-center">
                            <ArrowUpRight className="mt-1 ml-2" />
                          </div>
                        ) : (
                          <ArrowUpRight className="h-5 w-5 ml-2.5" />
                        )}
                      </a>
                    );
                  }

                  return (
                    <Link key={item.href} href={item.href} passHref>
                      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                      <a
                        href={item.href}
                        className="text-lg leading-7 text-white mb-7.5"
                      >
                        {item.name}
                      </a>
                    </Link>
                  );
                })}
            </div>
            <div className="col-span-3">
              {enabledPresentmentCurrencies &&
                enabledPresentmentCurrencies.length > 0 && (
                  <InputSelect
                    id="enabledPresentmentCurrencies"
                    options={enabledPresentmentCurrencies.map((item) => ({
                      label: `Shopping in ${item}${currencySymbol}`,
                      value: `${item}`,
                    }))}
                    onChange={(value) => {
                      selectCurrency(value);
                    }}
                    value={currency}
                    light
                    className="bg-grey-700"
                  />
                )}
              {/* Shopify: Shopping in AUD */}
              <div className="grid grid-cols-4 gap-4 mt-8 w-max">
                {data?.attributes?.paymentOptions?.data?.length > 0 && (
                  <>
                    {data.attributes.paymentOptions.data.map(
                      (paymentOption) => {
                        let Icon = null;
                        switch (paymentOption.attributes.providor) {
                          case 'ApplePay':
                            Icon = PaymentApple;
                            break;
                          case 'GooglePay':
                            Icon = PaymentGoogle;
                            break;
                          case 'AMEX':
                            Icon = PaymentAmex;
                            break;
                          case 'JCB':
                            Icon = PaymentJcb;
                            break;
                          case 'Pay':
                            Icon = PaymentPay;
                            break;
                          case 'VISA':
                            Icon = PaymentVisa;
                            break;
                          case 'MasterCard':
                            Icon = PaymentMastercard;
                            break;
                          default:
                            break;
                        }

                        if (Icon)
                          return (
                            <Icon
                              key={paymentOption.attributes.providor}
                              className="h-6 w-9.5"
                            />
                          );
                        return null;
                      },
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
      <div className="py-5 bg-grey-700">
        <Wrapper>
          <div className="items-center justify-between w-full md:flex">
            <div className="grid w-full grid-cols-2 md:grid-cols-[auto_auto_auto_auto] gap-y-5 md:gap-10 md:w-auto">
              {secondaryLinks &&
                secondaryLinks.length > 0 &&
                secondaryLinks.map((item) => (
                  <Link key={item.href} href={item.href} passHref>
                    {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                    <a
                      href={item.href}
                      className="inline-block text-sm font-medium font-poppins text-grey-100"
                    >
                      {item.name}
                    </a>
                  </Link>
                ))}
            </div>
            <p className="font-poppins font-medium text-sm text-grey-300 mt-7.5 md:mt-0">
              &copy;Tapt {new Date().getFullYear()}
            </p>
          </div>
        </Wrapper>
      </div>
    </footer>
  );
}

export default Footer;
