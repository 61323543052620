import { Icon } from '@/types/Icon';

export default function SocialWechat({ className }: Icon): JSX.Element {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8364 8.10782C17.3984 8.05382 16.9545 8.01604 16.4999 8.01604C11.5364 8.01604 7.50014 11.3897 7.50014 15.5365C7.50014 16.0193 7.55988 16.4898 7.66501 16.9487C6.8957 16.8541 6.17544 16.6509 5.4823 16.404L2.24996 17.7923L3.4365 15.4129C1.34708 14.0385 0 11.9143 0 9.52007C0 5.36737 4.02899 2 9.00014 2C13.3921 2 17.0446 4.63036 17.8364 8.10782ZM13.125 6.51197C13.125 5.88911 12.6213 5.38381 12.0002 5.38381C11.3787 5.38381 10.8751 5.88906 10.8751 6.51197C10.8751 7.13503 11.3787 7.63997 12.0002 7.63997C12.6213 7.63997 13.125 7.13503 13.125 6.51197ZM4.87505 6.51197C4.87505 7.13503 5.37879 7.63997 6.00003 7.63997C6.62116 7.63997 7.125 7.13503 7.12506 6.51197C7.12506 5.88911 6.62121 5.38381 6.00003 5.38381C5.37884 5.38381 4.87505 5.88911 4.87505 6.51197Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4999 9.52002C20.6412 9.52002 24 12.2137 24 15.5363C24 17.46 22.854 19.1534 21.1019 20.2546L22.5 23.0564L18.4574 21.3195C17.8288 21.4562 17.181 21.5524 16.4999 21.5524C12.3584 21.5524 9.00012 18.8587 9.00012 15.5363C9.00012 12.2137 12.3584 9.52002 16.4999 9.52002ZM13.125 14.032C13.125 14.6552 13.6288 15.1603 14.25 15.1603C14.8713 15.1603 15.3749 14.6552 15.3749 14.032C15.3749 13.4093 14.8714 12.9042 14.25 12.9042C13.6288 12.9042 13.125 13.4091 13.125 14.032ZM17.625 14.032C17.625 14.6552 18.1284 15.1603 18.7499 15.1603C19.3713 15.1603 19.8749 14.6552 19.8749 14.032C19.8749 13.4093 19.3713 12.9042 18.7499 12.9042C18.1284 12.9042 17.625 13.4091 17.625 14.032Z"
        fill="currentColor"
      />
    </svg>
  );
}
